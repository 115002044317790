<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
        :user-data="userData"
      />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import { getUserData } from '@/auth/utils'
import axios from '@/libs/axios'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      userData: null,
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  async created() {
    await axios.get('/client').then(resp => {
      localStorage.setItem('userData', JSON.stringify(resp.data))
      this.userData = getUserData()
    })
  },
}
</script>
